<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="isCurrentLoading" class="ssl-card__loader">
        <base-loader class="ssl-card__loader-item" />
      </div>
      <div v-else>
        <!--        <plain-button icon="arrow-left" color="dim" @click="$router.back()">-->
        <!--          {{ $t('back') }}-->
        <!--        </plain-button>-->
        <!--        <div class="ssl-card__content note-color">{{ ssl.title_name }}</div>-->
        <layout-title
          :cn="tariff.CN"
          :title="tariff.title"
          :cost="periodCost"
          :specs="specsObj"
          :status="tariff.status"
          class="ssl-detail__head"
        >
        </layout-title>
        <tabs :list="nav" class="medium-title">
          <template v-slot:item="{ item }">
            <router-link exact-active-class="active" :to="item.to">
              {{ item.title }}
            </router-link>
          </template>
        </tabs>
        <transition name="slide-fade">
          <router-view v-if="tariff" :tariff="tariff"></router-view>
        </transition>
        <!--        <div>{{ $t('info') }}:</div>-->
        <!--        <main-card class="main-card ssl-card main-card&#45;&#45;medium">-->
        <!--          <template #header></template>-->
        <!--        </main-card>-->
      </div>
    </transition>
  </div>
</template>

<script>
import tabs from '@/components/Tabs/Tabs.vue';
// import MainCard from '@/components/MainCard/MainCard';
import storeMixin from '@/layouts/SSL/mixins/index.js';
import LayoutTitle from '@/components/LayoutTitle/LayoutTitle';
import breakpoint from '@/utils/breakpoints';

export default {
  name: 'TariffInfo',
  components: {
    LayoutTitle,
    // MainCard,
    tabs,
  },
  mixins: [storeMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      isCurrentLoading: true,
      isServerError: false,
      nav: [
        {
          title: this.$t('nav.view'),
          to: { name: 'viewSsl' },
        },
        // {
        //   title: this.$t('nav.files'),
        //   to: { name: 'filesSsl' },
        // },
        // {
        //   title: this.$t('nav.backup'),
        //   to: { name: 'backupZmail' },
        // },
        {
          title: this.$t('nav.history'),
          to: { name: 'historySsl' },
        },
      ],
      isMobile: true,
    };
  },
  computed: {
    periodCost() {
      return this.$n(this.tariff.period_cost, 'currency', this.$i18n.locale);
    },
    tariff() {
      return this.$store.getters['moduleSSL/GET_CURRENT_HOSTING'];
    },
    specsObj() {
      const specs = this.tariff.specs;
      let list = [];
      // if (specs.datacentername) list.push(specs.datacentername);
      // list.push(this.$n(this.tariff.cost, 'currency', this.$i18n.locale));
      return {
        list,
      };
    },
    ssl() {
      return this.$store.state.moduleSSL.current;
    },
    loading() {
      return this.$store.state.moduleSSL.isLoading;
    },
    isSuspended() {
      return this.tariff.employeesuspend === 'on';
    },
    isAbuseSuspended() {
      return this.tariff.abusesuspend === 'on';
    },
  },
  beforeMount() {
    this.$store.dispatch('moduleSSL/currentSSL', +this.id);
  },
  created() {
    if (!this.list.length) {
      this.fetchList()
        .then(() => {
          if (this.checkNeedTariff()) this.fetchCurrent(+this.id);
          else this.isCurrentLoading = false;
        })
        .catch(e => {
          this.isCurrentLoading = false;
          if (e.status && e.status === 520) this.isServerError = true;
        });
    } else if (this.checkNeedTariff()) {
      this.fetchCurrent(+this.id);
    } else {
      this.isCurrentLoading = false;
    }
  },
  methods: {
    checkNeedTariff() {
      return !this.tariff.id || this.tariff.id !== +this.id;
    },
    fetchCurrent(id) {
      this.isCurrentLoading = true;
      this.$store
        .dispatch('moduleSSL/setCurrent', id)
        .then(tariff => {
          this.$store.dispatch('moduleSSL/fetchDetail', tariff.id);
        })
        .finally(() => {
          this.isCurrentLoading = false;
        });
    },
    setIsMobile() {
      this.isMobile = this.screenWidth < breakpoint.md;
    },
  },
};
</script>
<i18n>
{
  "ru": {
    "back": "Назад",
    "info": "Информация о сертификате",
    "nav": {
      "view": "Обзор",
      "backup": "Бэкапы",
      "files": "Файлы сертификата",
      "history": "История",
      "config": "Параметры"
    }
  }
}
</i18n>
<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
$vd-aside-size = 260px;
.ssl-detail {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;

  &__loader {
    flexy(center, center);
    min-size(100%);
    flex: 1 1 100%;
  }

  &__inner {
    position: relative;
    +breakpoint(md-and-up) {
      flexy(space-between, flex-start);
    }
  }

  &__content {
    flex: 1 1 100%;
    min-width: 'calc(100% - %s - 1.5rem)' % $vd-aside-size;
  }

  &__aside {
    display: none;

    +breakpoint(md-and-up) {
      display: block;
      flex: 0 0 $vd-aside-size;
      margin-left: 1.5rem;
    }
  }

  &__head {
    margin-bottom: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-bottom: 2.5rem;
    }
  }

  &__context-menu {
    max-width: 100%;
    width: calc(100vw - 4rem);

    +breakpoint(sm-and-up) {
      width: 100%;
    }
  }
}
.ssl-info {
  display: flex;
  flex-direction: column;
  width: 100%
  max-width: 925px;

  &-info {
    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between
      margin-top: 1rem;
      margin-bottom: 2.5rem;

      &-status {
        float right
        flex: 0 0 auto;
        order: 2;
        margin: 0 0 0 auto;

        +breakpoint(sm-and-up) {
          order: 2;
          margin: 0 1.25rem 0.25rem;
        }
      }
    }
  }
}
</style>
